* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Outfit', sans-serif; */
  font-family: 'Roboto Mono', monospace;
}

body {
  background: rgb(0, 0, 0);
}

h1, h4, p, a {
  color: #fff;
  text-decoration: none
}

ul {
  list-style-type: none;
}