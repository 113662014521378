.page-img {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
}

.page-img::after {
    content: "";
    background-image: url("https://i.imgur.com/iiOqrF9.jpg");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-img p {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 60px) {
    .page-img h1 {
        font-size: 2rem;
    }
}