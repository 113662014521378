.hero-container {
    width: 100%;
    height: 100vh;
    position: relative;
}

.background-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.hero-container::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;
}

.hero {
    height: 100%;
    width: 100%;
}

.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.hero .content p {
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}

.content .btn {
    margin: 1rem 0.2rem;
}

.btn {
    font-size: 1rem;
    text-transform: uppercase;
    background-color: #943828;
    color: rgb(4, 4, 4);
    border: 1px solid #fff;
    font-weight: 400;
    cursor: pointer;
}

.btn:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    transition: 0.3s;
}

.hero-link {
    display: flex;
    flex-direction: column;
}

@media screen  and (mex-width:640){
    .hero .content h1 {
        font-size: 2rem;
    }
    
    .hero .content p {
        font-size: 1rem;
    }
}

